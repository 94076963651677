<template>
  <div>
    <div :class="system.isPhone ? 'wap_apply_form_all' : 'apply_form_all'">
      <el-form
        ref="changePasswordForm"
        :label-position="system.isPhone ? 'top' : 'right'"
        label-width="auto"
        :model="form"
        :rules="rules"
        size="mini"
      >
        <form-bar :whoApply="whoApply" name=".firstName"></form-bar>

        <div class="form_css">
          <!-- 旧密码 -->
          <text-input
            inputType="password"
            prop="oldPassword"
            class="text_line"
            v-model="form.oldPassword"
            :label="$t('applyAgent.' + whoApply + '.oldPassword')"
          ></text-input>

          <!-- 新密码 -->
          <text-input
            inputType="password"
            prop="newPassword"
            class="text_line"
            v-model="form.newPassword"
            :label="$t('applyAgent.' + whoApply + '.newPassword')"
          ></text-input>

          <!-- 重复新密码 -->
          <text-input
            inputType="password"
            prop="newPassword2"
            class="text_line"
            v-model="form.newPassword2"
            :label="$t('applyAgent.' + whoApply + '.newPassword2')"
          ></text-input>
        </div>

        <el-form-item>
          <el-button
            class="next_but "
            type="primary"
            @click="submitForm('changePasswordForm')"
            >{{ $t("nameConfirmChange") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import textInput from "@/views/formComponents/textInput";
import formBar from "@/views/applyContact/applyAgent/components/formBar";
import { verifyPasswd } from "@/utils/pattern.js";

export default {
  components: {
    formBar,
    textInput
  },
  data() {
    var password = (rule, value, callback) => {
      if (!verifyPasswd(value)) {
        callback(new Error(this.$t("nameInputPasswordNotAllow"))); //密码不符合规则
      } else {
        callback();
      }
    };
    var password2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("nameInputPasswordAgin"))); //请再次输入密码
      } else if (value !== this.form.newPassword) {
        callback(new Error(this.$t("nameInputPasswordNotSame"))); //两次密码不一致
      } else {
        callback();
      }
    };

    return {
      whoApply: "changePassword",
      labelPosition: "right",
      form: {
        oldPassword: "",
        newPassword: "",
        newPassword2: ""
      },
      canSendCode: "false",
      rules: {
        newPassword: [{ required: true, validator: password, trigger: "blur" }],
        newPassword2: [
          { required: true, validator: password2, trigger: "blur" }
        ],
        oldPassword: [{ required: true, validator: password, trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tijiao();
        } else {
          return false;
        }
      });
    },
    uplodeChange(value) {
      console.log(this.$refs);
      this.form = { ...this.form, ...value };
      this.$refs["changePasswordForm"].validateField(Object.keys(value)[0]);
    },
    tijiao() {
      let data = { ...this.form };
      //进行提交
      this.$request("/api/front/user/modifyPassword", {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            this.$router.replace({
              path: "/"
            });
            this.$message({
              message: this.$t("nameChangeSucess"),
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.zanwei {
  height: 60px;
}
.next_but {
  width: 272px;
  height: 53px;
  border-radius: 67px;
  margin-top: 60px;
}
.apply_form_all {
  width: 100%;
  .text_line {
    // padding-top: 18px;
    // padding-bottom: 18px;
  }
  /deep/.form_css {
    max-width: 660px;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 36px;
    .el-form-item--mini {
      margin-bottom: 36px;
    }
    .el-textarea__inner.el-textarea__inner {
      height: 100px;
    }
  }
}
.wap_apply_form_all {
  .zanwei {
    height: calc(100vw * 1.2 / 37.5);
  }
  /deep/.el-form-item__content {
    text-align: center;
  }
  .next_but {
    width: calc(100vw * 32.5 / 37.5);
    height: calc(100vw * 4.2 / 37.5);
    opacity: 1;
    background: #1977fb;
    border-radius: 6px;
    margin: auto;
  }
  /deep/.form_css {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: calc(100vw * 4 / 37.5);
    .el-textarea__inner {
      height: calc(100vw * 12 / 37.5);
      border: none;
      border-bottom: 1px solid #dcdfe6;
      border-radius: 0;
    }
    .el-form-item__label {
      font-size: calc(100vw * 1.2 / 37.5);
      padding: 0;
      margin-top: calc(100vw * 2.4 / 37.5);
    }
    .el-input__inner {
      font-size: calc(100vw * 1.2 / 37.5);
      height: auto;
      line-height: calc(100vw * 3.2 / 37.5);
      border-radius: 0px;
      border: 1px none #dcdfe6;
      border-bottom: 1px solid #dcdfe6;
    }
    .uplode,
    .uplode_icon {
      width: calc(100vw * 15 / 37.5);
      height: calc(100vw * 10 / 37.5);
    }
  }
}
</style>
